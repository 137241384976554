import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import TestComponent from './components/testComponent/TestComponent';
import TermsAndConditions from "./components/termsAndConditions/TermsAndConditions";

const theme = createTheme({
  typography: {
    fontFamily: ['Raleway'].join(','),
  },
  palette: {
    primary: {
      main: '#41449F',
    },
  },
});

const searchIconElement = document.querySelector(".react-search-icon");
if (searchIconElement) {
  const root = ReactDOM.createRoot(searchIconElement);
  root.render(
    <ThemeProvider theme={theme}>
      <TestComponent />
    </ThemeProvider>
  );
}

const reactTermsAndConditions = document.querySelector(".react-terms-and-conditions");
if (reactTermsAndConditions) {
  const root = ReactDOM.createRoot(reactTermsAndConditions);
  root.render(
    <ThemeProvider theme={theme}>
      <TermsAndConditions />
    </ThemeProvider>
  );
}
