import React, { useEffect, useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Paper,
  Button,
} from '@mui/material';
// import anpcSal from '../../assets/images/anpc-sal.png';
// import anpcSol from '../../assets/images/anpc-sol.png';

export default function TermsAndConditions() {
  return (
    <Box>
      <p>
        Minolex este magazinul online de mobilier și accesorii pentru casă și
        birou care îți oferă o gamă variată de produse de calitate, cu livrare
        rapidă. Avem în stoc o selecție bogată de piese de mobilier, scaune,
        echipamente de joacă și articole textile, precum și o gamă variată de
        mobilier pentru birou și exterior. De asemenea, colaborăm cu furnizori
        de încredere, astfel încât să putem oferi și produse la comandă, în
        cazul în care nu avem un anumit produs pe stoc. Vino în magazinul nostru
        online și descoperă cea mai bună soluție pentru nevoile tale de mobilier
        și decor! Pentru orice întrebare sau comandă, nu ezitați să ne
        contactați prin e-mail la adresa{' '}
        <strong>
          <a href="mailto:vanzari@minolex.ro">vanzari@minolex.ro</a>
        </strong>
        .
      </p>

      {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingBottom: '20px' }}>
          <a href="https://anpc.ro/ce-este-sal/" target="_blank">
            <img
              alt="ANPC"
              src={anpcSal}
              style={{ width: '250px', height: '62px' }}
            />{' '}
          </a>
        </div>

        <div>
          <a href="https://ec.europa.eu/consumers/odr" target="_blank">
            <img
              alt="ANPC"
              src={anpcSol}
              style={{ width: '250px', height: '62px' }}
            />
          </a>
        </div>

        <div>&nbsp;</div>
      </div> */}

      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <p>Copyright © 2023 S.C. Minolex S.R.L.</p>
      </div>
    </Box>
  );
}
