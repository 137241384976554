import React, { Suspense } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import searchImage from '../../assets/images/magnifying-glass-icon-optimized.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(({ breakpoints }) => ({
  dialog: {
    height: 'fit-content!important',

    '& $paper': {
      [breakpoints.down('md')]: {
        margin: 0,
      },
      borderRadius: 0,
    },
  },
  paper: {},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const LazySearchWithAutocomplete = React.lazy(() => import('../SearchWithAutocomplete'));

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        startIcon={<SearchIcon sx={{ fontWeight: '100' }} />}
        sx={{
          textTransform: 'capitalize',
          color: '#000',
          fontWeight: '300',
          fontFamily: 'Raleway',
        }}
      >
        Cauta
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
        classes={{
          container: classes.dialog,
          paper: classes.paper,
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Cauta produsul dorit
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ minHeight: 200 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* Lazy load the SearchWithAutocomplete component */}
            <Suspense fallback={<div>Loading autocomplete...</div>}>
              <LazySearchWithAutocomplete handleClose={handleClose} />
            </Suspense>
          </Box>
          <Box sx={{ width: '30%', paddingTop: 2, margin: '0 auto' }}>
            <img loading="lazy" style={{ width: '100%' }} src={searchImage} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Inapoi
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
